/* BLUES */

$alt-row-light-blue: #e7f3fd;
$login-border: #bbddfa;
$middle-blue: #2e87d8;
$title-color: $middle-blue;
$login-background: $middle-blue;
$selected-column-border: lighten($middle-blue, 5%);
$selected-column-background: lighten($middle-blue, 15%);
$note-button-back: #004b8d;
$note-button-border: #00294D;

/* GREENS */

$ok-button-back: #7bc143;
$ok-button-border: #52812D;
$login-button-back: $ok-button-back;
$login-button-border: #6cb934;
$green-button-background: #cceacd;
$green-button-color: #5a9c3e;
$correct-color: $green-button-color;

/* REDS */

$error-color: #c43133;
$skip-button-back: #f85457;
$skip-button-border: #b83e41;
$light-red: #f07475;
$incorrect-color: $error-color;

/* YELLOWS / GOLDS*/

$order-button-back: #fda320;
$order-button-border: #BD7A18;
$highlight-color: hsla(44, 88%, 50%, 0.65);
$warm-fill-color: #f2e0aa;

/* NEUTRALS */

$glyph-back: white;
$window-background: white;
$inactive-cell-color: #eeeeee;
$br-report-border-color: silver;
$middle-grey: #898989;
$light-black: #333333;
$base-font-color: $middle-grey;
$text-input-border: $middle-grey;
$clear-button-back: $middle-grey;
$unselected-tab-color: lighten($middle-grey, 20%);
$unselected-tab-text-color: #656565;
$blank-hightlight-color: lighten($middle-grey, 40%);
$clear-button-border: #494949;
$br-report-text-color: black;