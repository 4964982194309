@import "app";
html {
    height: 100%;
}

body {
    margin: 0;
    height: 100vh;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

hr {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: .5;
    &:before {
        content: '';
        background: linear-gradient(to right, transparent, #818078, transparent);
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
    }
}

.tool-tip-info {
    border: 3px solid $middle-blue;
    border-radius: 5px;
    line-height: 10px;
    white-space: pre-line;
    &.max-width {
        .mdc-tooltip__surface {
            min-width: 60%;
            max-width: none;
        }
    }
    .mdc-tooltip__surface {
        --mdc-plain-tooltip-supporting-text-size: 18px;
        --mdc-plain-tooltip-container-color: #ffffff;
        --mdc-plain-tooltip-supporting-text-color: #555555;
        --mdc-plain-tooltip-supporting-text-font: "Karla Regular", "Karla", sans-serif;
        max-width: 300px;
        --mdc-plain-tooltip-supporting-text-line-height: normal;
    }
    &.red {
        border: 3px solid $light-red;
        .mdc-tooltip__surface {
            --mdc-plain-tooltip-supporting-text-color: red;
        }
    }
}

.popover-class {
    font-family: "Karla", sans-serif;
    font-size: 23px;
}

div#driver-popover-item {
    max-width: 40%;
    padding: 20px;
    .driver-popover-title {
        font-family: "Karla", sans-serif;
        text-align: center;
        border-bottom: 3px solid #2e87d8;
        font-size: 23px;
    }
    .driver-popover-description {
        font-size: 17px;
    }
    .driver-popover-footer {
        button {
            background-color: #ffffff;
            border-radius: 3px;
            border: none;
            box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 35%);
            color: #2664a0;
            cursor: pointer;
            font-family: "Karla", sans-serif;
            font-weight: 600;
            font-size: 1rem;
            width: auto;
            height: 30px;
            // padding: 1px 20px;
            text-shadow: none;
        }
        .driver-next-btn {
            color: #ffffff;
            background-color: #2f86d8;
        }
    }
}

.loader {
    margin: auto;
    .spinner {
        margin: 100px auto;
        width: 40px;
        height: 40px;
        position: relative;
    }
    .cube1,
    .cube2 {
        background-color: $middle-blue;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
        animation: sk-cubemove 1.8s infinite ease-in-out;
    }
    .cube2 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }
    @-webkit-keyframes sk-cubemove {
        25% {
            -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5)
        }
        50% {
            -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg)
        }
        75% {
            -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5)
        }
        100% {
            -webkit-transform: rotate(-360deg)
        }
    }
    @keyframes sk-cubemove {
        25% {
            transform: translateX(42px) rotate(-90deg) scale(0.5);
            -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
        }
        50% {
            transform: translateX(42px) translateY(42px) rotate(-179deg);
            -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
        }
        50.1% {
            transform: translateX(42px) translateY(42px) rotate(-180deg);
            -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
        }
        75% {
            transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
            -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
        }
        100% {
            transform: rotate(-360deg);
            -webkit-transform: rotate(-360deg);
        }
    }
}


.mat-mdc-progress-spinner {
    margin: auto;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 1rem;
}

.mdc-tab {
    --mdc-typography-button-font-family: "Karla", sans-serif;
    --mdc-typography-button-font-size: 1rem;
}

.mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
    color: rgba(0,0,0,.87);
}
.snackbar {
    color: #fff;
    --mdc-snackbar-supporting-text-size: 16px;
    --mdc-snackbar-container-color: #3f51b5;
    // --mdc-snackbar-supporting-text-color: #000;
    &.blue {
        --mdc-snackbar-container-color: #3f51b5;
    }
    &.green {
        --mdc-snackbar-container-color: #7bc143;
    }
    &.warn, &.error  {
        --mdc-snackbar-container-color: #ff4433;
    }
    &.accent {
        --mdc-snackbar-container-color: #ff4081;
    }
}
// Update for Red words options
.mat-mdc-option .mdc-list-item__primary-text {
    flex-grow: 1;
}

.toggle-blue {
    --mat-standard-button-toggle-selected-state-text-color: #ffffff;
    --mat-standard-button-toggle-selected-state-background-color: #2e87d8;
}

.toggle-grey {
    --mat-standard-button-toggle-selected-state-text-color: #ffffff;
    --mat-standard-button-toggle-selected-state-background-color: #656c74;
}

.toggle-red {
    --mat-standard-button-toggle-selected-state-text-color: #ffffff;
    --mat-standard-button-toggle-selected-state-background-color: #ec2c2c;
}

.mat-icon {
    &.mat-red {
        --mat-icon-color: #ec2c2c;
    }

    &.mat-grey {
        --mat-icon-color: gray;
    }

    &.mat-green {
        --mat-icon-color: #7bc143;
    }
}