@import "common-colors";
body {
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: $base-font-color;
    background-color: $window-background;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

app-root {
    text-align: center;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    min-height: 100vh;
}

app-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // border-bottom: solid 2px $middle-grey;
    font-weight: 700;
    font-family: "Karla Bold", "Karla Regular", "Karla", sans-serif;
    padding: 10px 0px;
    // margin-top: 10px;
    box-shadow: 0px -5px 15px;
}

app-header-right {
    flex: 4;
    // border-bottom: solid 1px silver;
    display: flex;
    justify-content: flex-end;
}

app-header-left {
    flex: 4;
    // border-bottom: solid 1px silver;
    display: flex;
    justify-content: flex-start;
}

app-header-center {
    flex: 1;
    // border: solid 1px silver;
    border-top: 0;
}

app-header,
app-footer {
    //flex: 1;
}

app-footer {
    margin-top: auto;
}

app-content {
    border-bottom: solid 1px silver;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}